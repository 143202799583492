// filterPickupTime.js
import moment from 'moment';

const getEarliestPickupTime = (cart, categories, restaurantData) => {
  if (!restaurantData?.restaurants_by_pk?.restaurant_hours) {
    return null;
  }

  // 取得當前時間
  const now = moment();
  const today = now.day();
  
  // 取得今日營業時間
  const todayHours = restaurantData.restaurants_by_pk.restaurant_hours.find(
    h => h.day_of_week === today
  );
  
  if (!todayHours) {
    return null;
  }

  // 取得準備時間
  const prepTime = restaurantData.restaurants_by_pk.dish_preparation_time || 0;
  
  // 計算營業時間
  const openTime = moment(todayHours.open_time, 'HH:mm:ss');
  const closeTime = moment(todayHours.close_time, 'HH:mm:ss');
  
  // 計算最早可取餐時間（當前時間 + 準備時間）
  let earliestTime = moment.max(
    now.clone().add(prepTime, 'minutes'),
    openTime.clone().add(prepTime, 'minutes')
  );

  // 如果已經超過今日營業時間，返回 null
  if (earliestTime.isAfter(closeTime)) {
    return null;
  }

  // 檢查購物車中所有餐點的類別時間限制
  const cartCategories = new Set();
  cart.forEach(item => {
    const category = categories.find(cat => 
      cat.dishes.some(dish => dish.id === item.id)
    );
    if (category) {
      cartCategories.add(category);
    }
  });

  // 遍歷所有可能的時間（以10分鐘為間隔）直到找到符合所有限制的時間
  let currentTime = earliestTime.clone();
  while (currentTime.isBefore(closeTime)) {
    let isValidTime = true;

    // 檢查每個類別的時間限制
    for (const category of cartCategories) {
      if (category.is_time_restricted) {
        const startTime = moment(category.start_time, 'HH:mm:ss');
        const endTime = moment(category.end_time, 'HH:mm:ss');

        // 處理跨午夜的情況
        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
          if (currentTime.isBefore(startTime)) {
            currentTime.add(1, 'day');
          }
        }

        if (!currentTime.isBetween(startTime, endTime, undefined, '[]')) {
          isValidTime = false;
          break;
        }
      }
    }

    if (isValidTime) {
      return currentTime.toDate();
    }

    currentTime.add(10, 'minutes');
  }

  return null;
};

// 工具函數：檢查時間是否在指定範圍內
const isTimeInRange = (time, startTime, endTime) => {
  const currentTime = moment(time);
  const start = moment(startTime, 'HH:mm:ss');
  const end = moment(endTime, 'HH:mm:ss');

  // 處理跨午夜的情況
  if (end.isBefore(start)) {
    end.add(1, 'day');
    if (currentTime.isBefore(start)) {
      currentTime.add(1, 'day');
    }
  }

  return currentTime.isBetween(start, end, undefined, '[]');
};

// 工具函數：取得分類的可用時間範圍
const getCategoryTimeRange = (category) => {
  if (!category.is_time_restricted) {
    return { isRestricted: false };
  }

  return {
    isRestricted: true,
    startTime: category.start_time,
    endTime: category.end_time
  };
};

// 驗證取餐時間是否符合所有餐點的類別時間限制
const validatePickupTimeForCategories = (pickupTime, cart, categories) => {
  // 如果購物車是空的，直接返回 true
  if (!cart || cart.length === 0) return true;

  // 獲取購物車中所有餐點的類別
  const cartCategories = new Set();
  cart.forEach(item => {
    const category = categories.find(cat => 
      cat.dishes.some(dish => dish.id === item.id)
    );
    if (category) {
      cartCategories.add(category);
    }
  });

  // 檢查每個相關類別的時間限制
  for (const category of cartCategories) {
    const timeRange = getCategoryTimeRange(category);
    
    // 如果該類別有時間限制
    if (timeRange.isRestricted) {
      const isValidTime = isTimeInRange(
        pickupTime,
        timeRange.startTime,
        timeRange.endTime
      );

      if (!isValidTime) {
        return false;
      }
    }
  }

  return true;
};

// 建立 filterPickupTime 函數工廠
const createFilterPickupTime = (restaurantData, cart, categories) => {
    return (time) => {
      const businessHours = getTodayBusinessHours(restaurantData);
      if (!businessHours) return false;
  
      const { openTime, closeTime, prepTime } = businessHours;
      const selectedTime = moment(time);
      const now = moment();
  
      // 確保選擇時間在現在 + 準備時間之後
      const earliestTime = moment.max(
        now.add(prepTime, 'minutes'),
        moment(openTime).add(prepTime, 'minutes')
      );
  
      // 基本營業時間檢查
      const isWithinBusinessHours = selectedTime.isBetween(
        earliestTime, 
        closeTime, 
        null, 
        "[]"
      );
  
      if (!isWithinBusinessHours) return false;
  
      // 檢查餐點類別的時間限制
      return validatePickupTimeForCategories(selectedTime, cart, categories);
    };
  };

// 計算當日營業時間
const getTodayBusinessHours = (restaurantData) => {
    if (!restaurantData?.restaurants_by_pk?.restaurant_hours) return null;
    
    const today = new Date().getDay();
    const todayHours = restaurantData.restaurants_by_pk.restaurant_hours.find(
      h => h.day_of_week === today
    );
    
    if (!todayHours) return null;
    
    return {
      openTime: moment(todayHours.open_time, 'HH:mm:ss'),
      closeTime: moment(todayHours.close_time, 'HH:mm:ss'),
      prepTime: restaurantData.restaurants_by_pk.dish_preparation_time || 0
    };
  };


// 過濾可選取餐時間
const filterPickupTime = (time, cart, categories, restaurantData) => {
    const businessHours = getTodayBusinessHours(restaurantData);
    if (!businessHours) return false;

    const { openTime, closeTime, prepTime } = businessHours;
    const selectedTime = moment(time);
    const now = moment();

    
    
    //condition 1: 確保選擇時間在現在 + 準備時間之後
    const earliestTime = moment.max(
    now.add(prepTime, 'minutes'),
    openTime.add(prepTime, 'minutes')
    );

    //condition 2: 確保在營業時間內
    const isWithinHours = selectedTime.isBetween(earliestTime, closeTime, null, "[]");
    if (!isWithinHours) return false;

    //condition 3: 驗證是否符合所有餐點的 類別 時間限制
    const x= validatePickupTimeForCategories (selectedTime, cart, categories);
    return x;
  };

  export { filterPickupTime, getEarliestPickupTime }; 

