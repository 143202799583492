import React from 'react';

const calculateItemPrice = (item, dishOptionItems) => {
  // 基本價格
  let basePrice = item.price;
  
  // 計算所有選項的額外價格
  let optionsTotal = 0;
  Object.entries(item.options).forEach(([optionId, selectedItems]) => {
    if (Array.isArray(selectedItems)) {
      // 如果是多選選項（checkbox）
      selectedItems.forEach(itemId => {
        const optionItem = dishOptionItems.find(oi => oi.id === itemId);
        if (optionItem) {
          optionsTotal += optionItem.price;
        }
      });
    } else {
      // 如果是單選選項（radio）
      const optionItem = dishOptionItems.find(oi => oi.id === selectedItems);
      if (optionItem) {
        optionsTotal += optionItem.price;
      }
    }
  });

  // 計算總價 = (基本價格 + 選項總價) × 數量
  const total = (basePrice + optionsTotal) * item.quantity;
  
  return total;
};

const OrderTotalCalculator = ({ cart, dishOptionItems }) => {
  const total = cart.reduce((acc, item) => {
    return acc + calculateItemPrice(item, dishOptionItems);
  }, 0);

  return (
    <div className="font-semibold text-lg">
      總計: ${total}
    </div>
  );
};

export default OrderTotalCalculator;
export { calculateItemPrice };