import React, { useState, useEffect, useRef } from 'react';
import { 
  ShoppingCart, 
  ChevronRight, 
  ChevronLeft,
  Clock, 
  AlertCircle, 
  Loader2,
  X, 
  Menu,
  Plus,
  Minus,
  CheckCircle 
} from 'lucide-react';

// OrderTypeModal Component
const OrderTypeModal = ({ isOpen, onClose, onSelectType, announcement, isAnnouncementEnabled }) => {
    if (!isOpen) return null;
  
    // 處理換行符號，將 \n 轉換為 <br />
    const formatAnnouncement = (text) => {
      if (!text) return null;
      
      // 將不同類型的換行符號統一處理
      const normalizedText = text.replace(/\\n/g, '\n');
      const lines = normalizedText.split('\n');
      
      return lines.map((line, index) => (
        <div key={index} className="mb-1">
          {line || '\u00A0'}
        </div>
      ));
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg w-full max-w-sm mx-4 p-6">
          <h2 className="text-xl font-bold mb-4 text-center">選擇用餐方式</h2>
          
          {isAnnouncementEnabled && announcement && (
            <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg">
              <div className="flex items-start">
                <AlertCircle className="w-5 h-5 text-blue-500 mt-0.5 mr-2 flex-shrink-0" />
                <div>
                  <h3 className="font-semibold text-blue-900">餐廳公告</h3>
                  <p className="text-sm text-blue-800 mt-1 whitespace-pre-wrap break-words">
                    {formatAnnouncement(announcement)}
                  </p>
                </div>
              </div>
            </div>
          )}
          
          <div className="space-y-3">
            <button
              onClick={() => {
                onSelectType('dineIn');
                onClose();
              }}
              className="w-full py-3 bg-black text-white rounded-lg font-semibold 
                       hover:bg-gray-800 transition-colors duration-200"
            >
              內用
            </button>
            <button
              onClick={() => {
                onSelectType('takeout');
                onClose();
              }}
              className="w-full py-3 bg-black text-white rounded-lg font-semibold 
                       hover:bg-gray-800 transition-colors duration-200"
            >
              外帶
            </button>
            <button
              onClick={() => {
                onSelectType('delivery');
                onClose();
              }}
              className="w-full py-3 bg-black text-white rounded-lg font-semibold 
                       hover:bg-gray-800 transition-colors duration-200"
            >
              外送
            </button>
          </div>
        </div>
      </div>
    );
  };

export default OrderTypeModal;      
