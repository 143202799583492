import React, { useMemo } from 'react';
import { X, Plus, Minus, Trash2, AlertCircle, ShoppingBag, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const PriceCalculator = ({ item, dishOptionItems, quantity = 1, showDetails = false }) => {
  const calculatedPrice = useMemo(() => {
    try {
      // 基本價格
      let basePrice = Number(item.price) || 0;
      
      // 選項加價計算
      let optionsTotal = 0;
      if (item.options) {
        Object.entries(item.options).forEach(([optionId, selectedItems]) => {
          if (Array.isArray(selectedItems)) {
            selectedItems.forEach(itemId => {
              const optionItem = dishOptionItems.find(oi => oi.id === parseInt(itemId));
              if (optionItem) {
                optionsTotal += Number(optionItem.price) || 0;
              }
            });
          } else {
            const optionItem = dishOptionItems.find(oi => oi.id === parseInt(selectedItems));
            if (optionItem) {
              optionsTotal += Number(optionItem.price) || 0;
            }
          }
        });
      }

      const total = (basePrice + optionsTotal) * quantity;
      return {
        basePrice,
        optionsTotal,
        total: Number(total.toFixed(2))
      };
    } catch (error) {
      console.error('Price calculation error:', error);
      return { basePrice: 0, optionsTotal: 0, total: 0 };
    }
  }, [item, dishOptionItems, quantity]);

  if (!showDetails) {
    return <span>${calculatedPrice.total}</span>;
  }

  return (
    <div className="text-sm space-y-1">
      <div className="flex justify-between">
        <span>基本價格:</span>
        <span>${calculatedPrice.basePrice}</span>
      </div>
      {calculatedPrice.optionsTotal > 0 && (
        <div className="flex justify-between text-gray-600">
          <span>選項加價:</span>
          <span>+${calculatedPrice.optionsTotal}</span>
        </div>
      )}
      <div className="flex justify-between font-medium">
        <span>小計 (x{quantity}):</span>
        <span>${calculatedPrice.total}</span>
      </div>
    </div>
  );
};

const CartItem = ({ 
  item, 
  index, 
  dishOptions, 
  dishOptionItems, 
  onUpdateQuantity, 
  onRemoveItem 
}) => {
  const handleQuantityChange = (change) => {
    const newQuantity = item.quantity + change;
    if (newQuantity >= 1 && newQuantity <= 99) {
      onUpdateQuantity(index, newQuantity);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="flex border-b border-gray-100 pb-4 mb-4"
    >
      <div className="flex-1 min-w-0">
        <h3 className="font-semibold text-gray-900 truncate">{item.name}</h3>
        
        <div className="flex items-center space-x-2 my-2">
          <button
            onClick={() => handleQuantityChange(-1)}
            className="p-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors
                     disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={item.quantity <= 1}
            aria-label="減少數量"
          >
            <Minus className="w-4 h-4" />
          </button>
          
          <span className="w-8 text-center font-medium">
            {item.quantity}
          </span>
          
          <button
            onClick={() => handleQuantityChange(1)}
            className="p-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors
                     disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={item.quantity >= 99}
            aria-label="增加數量"
          >
            <Plus className="w-4 h-4" />
          </button>
        </div>

        <div className="space-y-1 text-sm text-gray-500">
          {Object.entries(item.options).map(([optionId, selectedItems]) => {
            const option = dishOptions.find(o => o.id === parseInt(optionId));
            if (!option) return null;

            if (Array.isArray(selectedItems)) {
              return selectedItems.map(itemId => {
                const optionItem = dishOptionItems.find(oi => oi.id === itemId);
                if (!optionItem) return null;
                return (
                  <p key={itemId}>
                    {option.name}: {optionItem.name}
                    {optionItem.price > 0 && ` (+$${optionItem.price})`}
                  </p>
                );
              });
            } else {
              const optionItem = dishOptionItems.find(oi => oi.id === selectedItems);
              if (!optionItem) return null;
              return (
                <p key={optionId}>
                  {option.name}: {optionItem.name}
                  {optionItem.price > 0 && ` (+$${optionItem.price})`}
                </p>
              );
            }
          })}
          
          {item.note && (
            <p className="text-gray-500 italic mt-1">
              備註: {item.note}
            </p>
          )}
        </div>

        <div className="mt-2">
          <PriceCalculator
            item={item}
            dishOptionItems={dishOptionItems}
            quantity={item.quantity}
            showDetails={true}
          />
        </div>
      </div>

      <button
        onClick={() => onRemoveItem(index)}
        className="ml-4 p-2 text-red-500 hover:text-red-700 hover:bg-red-50 
                 rounded-lg transition-colors self-start"
        aria-label="移除商品"
      >
        <Trash2 className="w-5 h-5" />
      </button>
    </motion.div>
  );
};

const CartModal = ({ 
  isOpen, 
  onClose, 
  cart, 
  onRemoveItem, 
  onUpdateQuantity, 
  dishOptions, 
  dishOptionItems, 
  onCheckout 
}) => {
  // 修改總價計算邏輯
  const totalPrice = useMemo(() => {
    return cart.reduce((sum, item) => {
      // 基本價格
      let basePrice = Number(item.price) || 0;
      
      // 選項加價計算
      let optionsTotal = 0;
      if (item.options) {
        Object.entries(item.options).forEach(([optionId, selectedItems]) => {
          if (Array.isArray(selectedItems)) {
            // 多選選項的價格計算
            selectedItems.forEach(itemId => {
              const optionItem = dishOptionItems.find(oi => oi.id === parseInt(itemId));
              if (optionItem) {
                optionsTotal += Number(optionItem.price) || 0;
              }
            });
          } else {
            // 單選選項的價格計算
            const optionItem = dishOptionItems.find(oi => oi.id === parseInt(selectedItems));
            if (optionItem) {
              optionsTotal += Number(optionItem.price) || 0;
            }
          }
        });
      }

      // 計算單項商品總價 = (基本價格 + 選項加價) × 數量
      const itemTotal = (basePrice + optionsTotal) * item.quantity;
      return sum + itemTotal;
    }, 0);
  }, [cart, dishOptionItems]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end sm:items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className="bg-white w-full max-w-md rounded-t-2xl sm:rounded-2xl max-h-[90vh] 
                 flex flex-col shadow-xl"
      >
        {/* Header */}
        <div className="px-4 py-3 border-b border-gray-100 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ShoppingBag className="w-5 h-5" />
            <h2 className="text-xl font-bold">購物車</h2>
            <span className="text-sm text-gray-500">
              ({cart.length} 項商品)
            </span>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="關閉"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto overscroll-contain px-4 py-2">
          {cart.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-12 text-gray-500">
              <ShoppingBag className="w-12 h-12 mb-4" />
              <p className="text-lg font-medium">購物車是空的</p>
              <p className="text-sm mt-2">快去選購喜歡的餐點吧！</p>
            </div>
          ) : (
            <AnimatePresence>
              {cart.map((item, index) => (
                <CartItem
                  key={`${item.id}-${index}`}
                  item={item}
                  index={index}
                  dishOptions={dishOptions}
                  dishOptionItems={dishOptionItems}
                  onUpdateQuantity={onUpdateQuantity}
                  onRemoveItem={onRemoveItem}
                />
              ))}
            </AnimatePresence>
          )}
        </div>

        {/* Footer */}
        {cart.length > 0 && (
          <div className="border-t border-gray-100 p-4 bg-white">
            <div className="flex justify-between items-center mb-4">
              <span className="font-medium">總計金額</span>
              <span className="text-xl font-bold">${totalPrice}</span>
            </div>
            
            <button
              onClick={onCheckout}
              className="w-full bg-black text-white py-3 px-4 rounded-lg 
                       font-semibold hover:bg-gray-800 transition-colors
                       flex items-center justify-center gap-2"
            >
              前往結帳
              <motion.div
                animate={{ x: [0, 4, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
              >
                <ChevronRight className="w-5 h-5" />
              </motion.div>
            </button>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default CartModal;