import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { X, ChevronRight, ChevronLeft, Loader2,AlertCircle  } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import zhTWLocale from 'date-fns/locale/zh-TW';
import { addMinutes, setHours, setMinutes } from 'date-fns';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import moment from 'moment';
import { calculateItemPrice } from './Components/OrderTotalCalculator';
import {filterPickupTime, getEarliestPickupTime} from './Components/filterPickupTime';

registerLocale('zh-TW', zhTWLocale);
setDefaultLocale('zh-TW');

const PREPARATION_TIME = Number(process.env.REACT_APP_PREPARATION_TIME);
const RESTAURANT_ADDRESS = process.env.REACT_APP_RESTAURANT_ADDRESS ;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// 首先新增必要的 GraphQL 查詢
const GET_RESTAURANT_HOURS = gql`
  query GetRestaurantHours($restaurantId: Int!) {
    restaurants_by_pk(id: $restaurantId) {
      dish_preparation_time
      restaurant_hours {
        day_of_week
        open_time
        close_time
      }
    }
  }
`;


const CheckoutModal = ({ 
  isOpen, 
  onClose, 
  cart, 
  restaurantId,
  totalPrice, 
  tableNumber, 
  dishOptions, 
  dishOptionItems, 
  categories, // 添加 categories 作為 prop
  onCheckout, 
  orderType,
  onOrderTypeChange, // 添加這個屬性
  isProcessing,              
  setIsProcessing         
}) => {
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('');
  
  const [orderDetails, setOrderDetails] = useState(() => ({
    deliveryTime: '',
    address: '',
    name: '',
    phone: '',
    duration: '',
    priority: 3,
  }));

  const [center, setCenter] = useState({ lat: 25.0330, lng: 121.5654 });
  const [marker, setMarker] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [schedulingDetails, setSchedulingDetails] = useState(null);
  const [calculationError, setCalculationError] = useState(null);

  // 獲取餐廳營業時間資訊
  const { data: restaurantData } = useQuery(GET_RESTAURANT_HOURS, {
    variables: { restaurantId },
    skip: !isOpen || orderType !== 'takeout'
  });
  
  const autocompleteRef = useRef(null);

  // 當 modal 開啟時重新計算最早可用時間
  useEffect(() => {
    if (isOpen && orderType === 'takeout') {
      const earliestTime = getEarliestPickupTime(cart, categories, restaurantData);
      setOrderDetails(prev => ({
        ...prev,
        deliveryTime: earliestTime
      }));
    }
  }, [isOpen, orderType, cart, categories, restaurantData]);  
  

   // (a) 渲染內用訂單詳情
   const renderDineInDetails = () => (
    <>
      <div>
        <label className="block text-sm font-medium text-gray-700">桌號</label>
        <p className="mt-1 text-lg font-semibold">{tableNumber}</p>
      </div>

      <div className="mt-4 p-4 bg-blue-50 rounded-lg">
        <div className="flex items-start gap-2">
          <AlertCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-blue-700">
            <p className="font-medium">用餐提醒</p>
            <ul className="mt-1 space-y-1">
              <li>• 座位採隨機制，請自行尋找空位入座</li>
              <li>• 餐點製作完成後會送至您的座位</li>
              <li>• 如需協助請向服務人員詢問</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );

  // (b) 渲染外帶自取的訂單詳情
  const renderTakeoutDetails = () => (
    <>
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">
          希望取餐時間
        </label>
        <div className="mt-2">
          {orderDetails.deliveryTime ? (
            <DatePicker
              selected={orderDetails.deliveryTime}
              onChange={handleDeliveryTimeChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={10}
              timeCaption="時間"
              dateFormat="yyyy年MM月dd日 HH:mm"
              minDate={new Date()}
              filterTime={(time) => filterPickupTime(time, cart, categories, restaurantData)}
              placeholderText="選擇取餐時間"
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              locale="zh-TW"
            />
          ) : (
            <div className="p-4 bg-yellow-50 rounded-lg">
              <div className="flex items-start gap-2">
                <AlertCircle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                <div className="text-sm text-yellow-700">
                  <p className="font-medium">目前無法接單</p>
                  <p className="mt-1">
                    非常抱歉，目前已超過今日營業時間或所選餐點無法在當前時段供應。
                    建議您查看營業時間或調整訂單內容。
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 p-4 bg-blue-50 rounded-lg">
        <div className="flex items-start gap-2">
          <AlertCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-blue-700">
            <p className="font-medium">取餐提醒</p>
            <p className="mt-1">
              請在選擇的時間到店出示訂單編號取餐。如遇特殊情況需要更改取餐時間，
              請提前來電告知。
            </p>
          </div>
        </div>
      </div>
    </>
  );

 // (c) 渲染外送訂單詳情
 const renderDeliveryDetails = () => (
  <>
    <div>
      <label className="block text-sm font-medium text-gray-700">送餐地址</label>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          type="text"
          name="address"
          value={orderDetails.address}
          onChange={handleInputChange}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          placeholder="輸入或選擇外送地址"
        />
      </Autocomplete>
    </div>

    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '200px' }}
      center={center}
      zoom={12}
      options={{ 
        disableDefaultUI: true, 
        zoomControl: false, 
        gestureHandling: 'none',
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
          }
        ]
      }}
    >
      {marker && <Marker position={marker} />}
    </GoogleMap>

    {renderDeliveryTimeCalculation()}

    <div className="mt-4">
      <label className="block text-sm font-medium text-gray-700">姓名</label>
      <input
        type="text"
        name="name"
        value={orderDetails.name}
        onChange={handleInputChange}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        placeholder="請輸入收件人姓名"
      />
    </div>

    <div className="mt-4">
      <label className="block text-sm font-medium text-gray-700">電話</label>
      <input
        type="tel"
        name="phone"
        value={orderDetails.phone}
        onChange={handleInputChange}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        placeholder="請輸入聯絡電話"
      />
    </div>

    {calculationError && (
      <div className="mt-4 p-4 bg-red-50 rounded-lg">
        <div className="flex items-start gap-2">
          <AlertCircle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
          <p className="text-sm text-red-600">{calculationError}</p>
        </div>
      </div>
    )}
  </>
);

  // 驗證表單是否可以進入下一步
  const isFormValid = () => {
    switch (orderType) {
      case 'dineIn':
        return true; // 內用只需要桌號，已經在前面選好了
      
      case 'takeout':
        return orderDetails.deliveryTime;
      
      case 'delivery':
        return orderDetails.address &&
               orderDetails.name &&
               orderDetails.phone &&
               schedulingDetails; // 確保已經計算過送餐時間
      
      default:
        return false;
    }
  };
  

  const calculateTravelTime = async (origin, destination) => {
    return new Promise((resolve, reject) => {
      try {
        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.METRIC,
          },
          (response, status) => {
            if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
              const duration = Math.ceil(response.rows[0].elements[0].duration.value / 60);
              console.log('交通時間計算成功：', duration, '分鐘');
              resolve(duration);
            } else {
              console.error('Google Maps API 錯誤：', status);
              reject(new Error(`無法計算路程時間: ${status}`));
            }
          }
        );
      } catch (error) {
        console.error('計算交通時間時發生錯誤：', error);
        reject(error);
      }
    });
  };

  const checkScheduling = async (orderData) => {
    try {
      console.log('正在發送排程預估請求，數據：', orderData);
      
      const formattedData = {
        ...orderData,
        order: {
          ...orderData.order,
          endTime: moment(orderData.order.endTime).format(),
        }
      };

      if (!API_BASE_URL) {
        throw new Error('API_BASE_URL 未設定');
      }

      const response = await fetch(`${API_BASE_URL}/api/schedule/estimate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
      });

      if (!response.ok) {
        throw new Error(`排程預估請求失敗: ${response.status}`);
      }

      const result = await response.json();
      console.log('排程預估結果：', result);
      return result;
    } catch (error) {
      console.error('檢查排程時發生錯誤：', error);
      throw error;
    }
  };

  const calculateEarliestDeliveryTime = async () => {
    if (!orderDetails.address) {
      alert('請先輸入或選擇外送地址');
      return;
    }
  
    setIsCalculating(true);
    setCalculationError(null);
    console.log("開始估算最快可送達時間...");
  
    try {
      // 1. 計算交通時間
      const travelDuration = await calculateTravelTime(
        RESTAURANT_ADDRESS,
        orderDetails.address
      );
      console.log(`交通時間計算完成：${travelDuration} 分鐘`);
  
      // 2. 檢查排程
      const schedulingResult = await checkScheduling({
        deliverymanId: 'deliveryman1',
        order: {
          id: `temp-${Date.now()}`,
          endTime: moment().toISOString(),
          duration: travelDuration,
          priority: orderDetails.priority || 3,
          address: orderDetails.address,
          name: orderDetails.name,
          phone: orderDetails.phone,
          order_type: 'delivery',
        },
      });
  
      // 3. 整合時間資訊
      const details = {
        交通時間: travelDuration,
        廚房開始時間: schedulingResult.kitchen.startTime ? 
          moment(schedulingResult.kitchen.startTime).format('HH:mm') : '無法計算',
        廚房完成時間: schedulingResult.kitchen.endTime ? 
          moment(schedulingResult.kitchen.endTime).format('HH:mm') : '無法計算',
      };
  
      console.log("送餐時間計算完成：", details);
      setSchedulingDetails(details);
  
      // 4. 計算最早送達時間並更新預設值
      if (schedulingResult.kitchen?.endTime) {
        // 使用廚房完成時間 + 交通時間來計算最早送達時間
        const deliveryTime = moment(schedulingResult.kitchen.endTime)
          .add(travelDuration, 'minutes')
          .toDate();
  
        if (deliveryTime instanceof Date && !isNaN(deliveryTime)) {
          handleDeliveryTimeChange(deliveryTime);
        }
      }
  
      return details;
  
    } catch (error) {
      console.error('計算送達時間時發生錯誤：', error);
      setCalculationError(error.message);
      alert(`計算送達時間時發生錯誤：${error.message}`);
    } finally {
      setIsCalculating(false);
    }
  };

  const handleClose = () => {
    setStep(1);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleDeliveryTimeChange = (date) => {
    if (date && date instanceof Date && !isNaN(date)) {
      setOrderDetails(prev => ({ ...prev, deliveryTime: date }));
    }
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const newCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setCenter(newCenter);
      setMarker(newCenter);
      setOrderDetails(prev => ({ ...prev, address: place.formatted_address }));
    }
  };

  // 在進入第三步之前更新時間資訊
  const moveToStep3 = async () => {
    if (orderType === 'delivery' && !schedulingDetails) {
      // 如果是外送訂單但還沒有計算時間，則先計算
      try {
        await calculateEarliestDeliveryTime();
      } catch (error) {
        alert('請先估算送餐時間');
        return;
      }
    }
    setStep(3);
  };

  const handlePayment = async () => {
    try {
      if (orderType === 'delivery' && !schedulingDetails) {
        alert('請先估算送餐時間');
        return;
      }

      setIsProcessing(true); // 開始處理時顯示
      const orderInfo = {
        ...orderDetails,
        schedulingDetails,
        duration: schedulingDetails?.交通時間 , // 使用計算出的交通時間
        estimatedDeliveryTime: schedulingDetails ? 
          moment(schedulingDetails.廚房完成時間, 'HH:mm')
            .add(schedulingDetails.交通時間, 'minutes')
            .toDate() : 
          orderDetails.deliveryTime
      };

      console.log('送出訂單資訊：', orderInfo); // 用於除錯

      await onCheckout(paymentMethod, orderInfo);
      setStep(1);
      onClose();
    } catch (error) {
      console.error('結帳時發生錯誤：', error);
      alert('結帳過程中發生錯誤，請稍後再試。');
    } finally {
      setIsProcessing(false); // 結束處理時隱藏
    }
};

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    
    if (!selectedDate || isNaN(selectedDate)) {
      return false;
    }

    if (schedulingDetails?.最早可送達時間) {
      const earliestTime = moment(schedulingDetails.最早可送達時間, 'HH:mm');
      const selectedMoment = moment(selectedDate);
      return selectedMoment.isSameOrAfter(earliestTime);
    }

    return selectedDate >= addMinutes(currentDate, PREPARATION_TIME);
  };

  const renderCalculationResults = () => {
    if (calculationError) {
      return (
        <div className="mt-4 p-4 bg-red-100 rounded-lg">
          <p className="text-red-600">錯誤：{calculationError}</p>
        </div>
      );
    }

    if (schedulingDetails) {
      return (
        <div className="mt-4 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">預估送餐時間詳情</h3>
          <p className="text-sm mb-1">
            <span className="font-medium">廚房開始時間：</span>{schedulingDetails.廚房開始時間}
          </p>
          <p className="text-sm mb-1">
            <span className="font-medium">廚房完成時間：</span>{schedulingDetails.廚房完成時間}
          </p>
          <p className="text-sm mb-1">
            <span className="font-medium">預估交通時間：</span>{schedulingDetails.交通時間} 分鐘
          </p>
          <p className="text-base font-semibold mt-2">
            最早可送達時間：{moment(schedulingDetails.廚房完成時間, 'HH:mm')
              .add(schedulingDetails.交通時間, 'minutes')
              .format('HH:mm')}
            </p>
        </div>
      );
    }

    return null;
  };

  const renderDeliveryTimeCalculation = () => (
    <>
      <button
        onClick={calculateEarliestDeliveryTime}
        className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-400"
        disabled={isCalculating || !orderDetails.address}
      >
        {isCalculating ? '計算中...' : '估算 最快可送達時間'}
      </button>
      {renderCalculationResults()}
    </>
  );

  const renderStep1 = () => {
    // 計算單項商品的總價（包含選項加價和數量）
    const calculateItemTotalPrice = (item) => {
      // 基本價格
      let basePrice = Number(item.price) || 0;
      
      // 選項加價
      let optionsTotal = 0;
      Object.entries(item.options).forEach(([optionId, selectedItems]) => {
        if (Array.isArray(selectedItems)) {
          selectedItems.forEach(itemId => {
            const optionItem = dishOptionItems.find(oi => oi.id === parseInt(itemId));
            if (optionItem) {
              optionsTotal += Number(optionItem.price) || 0;
            }
          });
        } else {
          const optionItem = dishOptionItems.find(oi => oi.id === parseInt(selectedItems));
          if (optionItem) {
            optionsTotal += Number(optionItem.price) || 0;
          }
        }
      });
  
      // 計算總價 = (基本價格 + 選項加價) × 數量
      return (basePrice + optionsTotal) * item.quantity;
    };
  
    // 計算所有商品的總價
    const calculateTotalPrice = () => {
      return cart.reduce((total, item) => {
        return total + calculateItemTotalPrice(item);
      }, 0);
    };
  
    return (
      <div>
        <h2 className="text-xl font-bold mb-4">確認餐點</h2>
        
        <div className="max-h-60 overflow-y-auto mb-4">
          {cart.map((item, index) => {
            // 計算單品的基本價格和選項加價
            const basePrice = Number(item.price) || 0;
            let optionsTotal = 0;
            
            return (
              <div key={index} className="mb-2 pb-2 border-b">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="font-bold">{item.name} x {item.quantity}</p>
                    <p className="text-sm text-gray-600">
                      單價：${basePrice}
                    </p>
                  </div>
                  <p className="font-medium">
                    ${calculateItemTotalPrice(item)}
                  </p>
                </div>
                
                {/* 選項明細 */}
                {Object.entries(item.options).map(([optionId, selectedItems]) => {
                  const option = dishOptions.find(o => o.id === parseInt(optionId));
                  if (Array.isArray(selectedItems)) {
                    return selectedItems.map(itemId => {
                      const optionItem = dishOptionItems.find(oi => oi.id === itemId);
                      if (optionItem) {
                        optionsTotal += Number(optionItem.price) || 0;
                        return (
                          <p key={itemId} className="text-sm text-gray-500 flex justify-between">
                            <span>{option?.name}: {optionItem.name}</span>
                            {optionItem.price > 0 && (
                              <span>+${optionItem.price}</span>
                            )}
                          </p>
                        );
                      }
                      return null;
                    });
                  } else {
                    const optionItem = dishOptionItems.find(oi => oi.id === selectedItems);
                    if (optionItem) {
                      optionsTotal += Number(optionItem.price) || 0;
                      return (
                        <p key={optionId} className="text-sm text-gray-500 flex justify-between">
                          <span>{option?.name}: {optionItem.name}</span>
                          {optionItem.price > 0 && (
                            <span>+${optionItem.price}</span>
                          )}
                        </p>
                      );
                    }
                    return null;
                  }
                })}
                
                {/* 小計明細 */}
                {(optionsTotal > 0 || item.quantity > 1) && (
                  <div className="mt-1 text-sm text-gray-500 border-t border-gray-100 pt-1">
                    <div className="flex justify-between">
                      <span>基本價格</span>
                      <span>${basePrice}</span>
                    </div>
                    {optionsTotal > 0 && (
                      <div className="flex justify-between">
                        <span>選項加價</span>
                        <span>+${optionsTotal}</span>
                      </div>
                    )}
                    {item.quantity > 1 && (
                      <div className="flex justify-between">
                        <span>小計 (x{item.quantity})</span>
                        <span>${(basePrice + optionsTotal) * item.quantity}</span>
                      </div>
                    )}
                  </div>
                )}
                
                {/* 備註 */}
                {item.note && (
                  <p className="text-sm text-gray-500 mt-1 italic">
                    備註: {item.note}
                  </p>
                )}
              </div>
            );
          })}
        </div>
  
        {/* 總計金額 */}
        <div className="flex justify-between items-center py-3 border-t border-gray-200">
          <span className="font-medium text-lg">總計金額</span>
          <span className="font-bold text-xl">${calculateTotalPrice()}</span>
        </div>
  
        <button
          onClick={() => setStep(2)}
          className="w-full bg-black text-white px-4 py-3 rounded-lg 
                   flex items-center justify-center gap-2
                   hover:bg-gray-800 transition-colors duration-200 mt-4"
        >
          下一步
          <ChevronRight size={20} />
        </button>
      </div>
    );
  };




  const renderStep2 = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">訂單詳情</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">用餐方式</label>
          <p className="mt-1 text-lg font-semibold">
            {orderType === 'dineIn' ? '內用' : 
             orderType === 'takeout' ? '外帶自取' : '外送'}
          </p>
        </div>

        {orderType === 'takeout' && renderTakeoutDetails()}
        {orderType === 'delivery' && renderDeliveryDetails()}
        {orderType === 'dineIn' && renderDineInDetails()}
      </div>

      {/* 底部按鈕 */}
      <div className="flex justify-between mt-6">
        <button
          onClick={() => setStep(1)}
          className="flex items-center px-4 py-2 bg-gray-100 rounded-lg
                   hover:bg-gray-200 transition-colors"
        >
          <ChevronLeft className="w-5 h-5 mr-2" />
          上一步
        </button>
        
        <button
          onClick={moveToStep3}
          disabled={!isFormValid()}
          className={`flex items-center px-4 py-2 rounded-lg
            ${isFormValid() 
              ? 'bg-black text-white hover:bg-gray-800' 
              : 'bg-gray-200 text-gray-500 cursor-not-allowed'}
            transition-colors`}
        >
          下一步
          <ChevronRight className="w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div>
      <h2 className="text-xl font-bold mb-4">選擇支付方式</h2>
      
      {orderType === 'delivery' && schedulingDetails && (
        <div className="mb-4 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">送餐時間確認</h3>
          <p className="text-sm mb-1">
            <span className="font-medium">廚房開始時間：</span>{schedulingDetails.廚房開始時間}
          </p>
          <p className="text-sm mb-1">
            <span className="font-medium">廚房完成時間：</span>{schedulingDetails.廚房完成時間}
          </p>
          <p className="text-sm mb-1">
            <span className="font-medium">預估交通時間：</span>{schedulingDetails.交通時間} 分鐘
          </p>
          <p className="text-base font-semibold mt-2">
            預計送達時間：{moment(schedulingDetails.廚房完成時間, 'HH:mm')
              .add(schedulingDetails.交通時間, 'minutes')
              .format('HH:mm')}
          </p>
        </div>
      )}

      <div className="space-y-2 mb-4">
        {['Line Pay', '現金'].map((method) => (
          <button
            key={method}
            onClick={() => setPaymentMethod(method)}
            className={`w-full p-2 border rounded ${
              paymentMethod === method ? 'bg-green-200 text-black' : 'bg-white'
            }`}
          >
            {method}
          </button>
        ))}
      </div>
      <div className="flex justify-between">
        <button
          onClick={() => setStep(2)}
          className="bg-gray-300 text-black px-4 py-2 rounded flex items-center"
        >
          <ChevronLeft size={20} className="mr-2" /> 上一步
        </button>
        <button
          onClick={handlePayment}
          className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
          disabled={!paymentMethod}
        >
          確認付款
        </button>
      </div>
    </div>
  );

  const renderProgressBar = () => (
    <div className="flex justify-between mb-6">
      {['確認餐點', '訂單詳情', '選擇支付方式'].map((text, index) => (
        <div key={index} className="flex flex-col items-center">
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              step > index ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-600'
            }`}
          >
            {index + 1}
          </div>
          <span className="text-xs mt-1">{text}</span>
        </div>
      ))}
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">結帳</h2>
          <button 
            onClick={handleClose} 
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>
        {renderProgressBar()}
        {step === 1 ? renderStep1() : step === 2 ? renderStep2() : renderStep3()}

        {isProcessing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="flex flex-col items-center">
              <Loader2 className="animate-spin h-8 w-8 mb-4" />
              <p className="text-lg font-semibold">訂單處理中...</p>
              <p className="text-sm text-gray-600 mt-2">請稍候，正在確認訂單資訊</p>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default CheckoutModal;