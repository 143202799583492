import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LoadScript, useLoadScript  } from '@react-google-maps/api';
import OrderApp from './OrderApp';

// Apollo Client 設置（保持不變）
const hasuraLink = process.env.REACT_APP_HASURA_LINK;
if (!hasuraLink) {
  throw new Error('REACT_APP_HASURA_LINK is not defined in the environment');
}

const httpLink = createHttpLink({
  uri: hasuraLink,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


function App() {
  const { isLoaded, loadError } = useLoadScript({
    //googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    googleMapsApiKey: 'AIzaSyBOCq0j_2qYPs2zp5chhuP7bkyTkdHgrrA',
    libraries: ['places'],
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <ApolloProvider client={client}>
      <OrderApp restaurantId={18} />
    </ApolloProvider>
  );
}

export default App;
