import React, { useState, useEffect } from 'react';
import { X, AlertCircle, ChevronRight, Plus, Minus, CheckCircle } from 'lucide-react';

const DishOptionModal = ({ 
  dish, 
  isOpen, 
  onClose, 
  onAddToCart, 
  dishOptions, 
  dishOptionItems 
}) => {
  // 初始狀態
  const initialState = {
    selectedOptions: {},
    note: '',
    quantity: 1,
    validationErrors: {}
  };

  // 狀態管理
  const [selectedOptions, setSelectedOptions] = useState({});
  const [note, setNote] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [validationErrors, setValidationErrors] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  // 重置所有表單狀態的函數
  const resetForm = () => {
    setSelectedOptions({});
    setNote('');
    setQuantity(1);
    setValidationErrors({});
    calculateTotalPrice({});
  };

  // 每次打開modal時重置
  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen, dish]);

  // 計算總價
  const calculateTotalPrice = (currentOptions) => {
    if (!dish) return 0;
    let total = dish.price;

    Object.entries(currentOptions).forEach(([optionId, selectedItems]) => {
      const addOptionPrice = (itemId) => {
        const optionItem = dishOptionItems.find(oi => oi.id === itemId);
        if (optionItem) {
          total += optionItem.price;
        }
      };

      if (Array.isArray(selectedItems)) {
        selectedItems.forEach(addOptionPrice);
      } else {
        addOptionPrice(selectedItems);
      }
    });

    return total * quantity;
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice(selectedOptions));
  }, [selectedOptions, quantity]);

  // 準備排序後的選項
  const sortedOptions = dish?.comb_dish_options
    ? [...dish.comb_dish_options]
        .sort((a, b) => a.display_order - b.display_order)
        .map(combOption => {
          const option = dishOptions.find(o => o.id === combOption.dish_option.id);
          if (!option) return null;
          return {
            ...option,
            items: option.comb_option_items
              ? [...option.comb_option_items]
                  .sort((a, b) => a.display_order - b.display_order)
                  .map(coi => dishOptionItems.find(item => item.id === coi.dish_option_item.id))
                  .filter(Boolean)
              : []
          };
        })
        .filter(Boolean)
    : [];

  const handleOptionChange = (optionId, itemId) => {
    const option = dishOptions.find(o => o.id === optionId);
    if (!option) return;

    setSelectedOptions(prev => {
      let newSelected;
      if (option.type === 'radio') {
        newSelected = { ...prev, [optionId]: itemId };
      } else {
        const prevSelected = prev[optionId] || [];
        if (prevSelected.includes(itemId)) {
          newSelected = {
            ...prev,
            [optionId]: prevSelected.filter(id => id !== itemId)
          };
        } else {
          if (option.max_selections !== null && prevSelected.length >= option.max_selections) {
            setValidationErrors(prev => ({
              ...prev,
              [optionId]: `最多只能選擇 ${option.max_selections} 項`
            }));
            return prev;
          }
          newSelected = {
            ...prev,
            [optionId]: [...prevSelected, itemId]
          };
        }
      }

      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[optionId];
        return newErrors;
      });

      return newSelected;
    });
  };

  const validateSelections = () => {
    const errors = {};
    sortedOptions.forEach(option => {
      const selectedCount = (selectedOptions[option.id] || []).length;
      if (option.type === 'checkbox') {
        if (option.min_selections !== null && selectedCount < option.min_selections) {
          errors[option.id] = `請至少選擇 ${option.min_selections} 項`;
        }
      } else if (option.type === 'radio' && option.min_selections !== 0) {
        if (!selectedOptions[option.id]) {
          errors[option.id] = '請選擇一個選項';
        }
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateSelections()) {
      return;
    }
    // 加入購物車
    onAddToCart(dish, selectedOptions, note, quantity);
    
    // 重置所有選項
    resetForm();
    
    // 關閉 modal
    onClose();
  };

  const updateQuantity = (value) => {
    const newQuantity = Math.max(1, Math.min(99, quantity + value));
    setQuantity(newQuantity);
  };

  if (!isOpen || !dish) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-md rounded-2xl max-h-[90vh] flex flex-col relative">
        {/* 頂部區域：餐點資訊 */}
        <div className="p-6 border-b border-gray-100">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h2 className="text-xl font-bold mb-1">{dish.name}</h2>
              {dish.description && (
                <p className="text-gray-600 text-sm">{dish.description}</p>
              )}
            </div>
            <button 
              onClick={() => {
                resetForm();
                onClose();
              }}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>
          <div className="text-lg font-semibold">
            ${dish.price}
          </div>
        </div>

        {/* 選項區域 */}
        <div className="flex-1 overflow-y-auto px-6 py-4">
          <div className="space-y-6">
            {sortedOptions.map(option => (
              <div key={option.id} className="space-y-3">
                <div className="flex justify-between items-center">
                  <h3 className="font-semibold text-gray-900">
                    {option.name}
                  </h3>
                  {option.type === 'radio' && option.min_selections !== 0 && (
                    <div className="flex items-center text-green-600 text-sm">
                      <CheckCircle className="w-4 h-4 mr-1" />
                      <span>必填</span>
                    </div>
                  )}
                </div>

                {validationErrors[option.id] && (
                  <div className="flex items-center gap-2 p-3 bg-red-50 rounded-lg text-red-600 text-sm">
                    <AlertCircle className="w-4 h-4" />
                    <span>{validationErrors[option.id]}</span>
                  </div>
                )}

                <div className="space-y-2">
                  {option.items.map(item => (
                    <label 
                      key={item.id}
                      className={`flex items-center p-4 rounded-lg border border-gray-200
                        ${
                          option.type === 'radio' && selectedOptions[option.id] === item.id ||
                          option.type === 'checkbox' && selectedOptions[option.id]?.includes(item.id)
                            ? 'bg-black/5 border-black'
                            : 'hover:bg-gray-50'
                        }
                        transition-all cursor-pointer`}
                    >
                      <input
                        type={option.type === 'radio' ? 'radio' : 'checkbox'}
                        name={`option-${option.id}`}
                        checked={
                          option.type === 'radio'
                            ? selectedOptions[option.id] === item.id
                            : selectedOptions[option.id]?.includes(item.id)
                        }
                        onChange={() => handleOptionChange(option.id, item.id)}
                        className="mr-3"
                      />
                      <span className="flex-1 font-medium">{item.name}</span>
                      {item.price > 0 && (
                        <span className="text-gray-600">+${item.price}</span>
                      )}
                    </label>
                  ))}
                </div>
              </div>
            ))}

            {/* 備註區域 */}
            <div className="space-y-2">
              <label className="block font-semibold text-gray-900">備註</label>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="有什麼特別要求嗎？"
                className="w-full p-3 border border-gray-200 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-black/5"
                rows="3"
              />
            </div>
          </div>
        </div>

        {/* 底部區域：數量和提交 */}
        <div className="border-t border-gray-100 p-4 bg-white sticky bottom-0 rounded-b-2xl shadow-top">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center bg-gray-100 rounded-lg">
              <button 
                onClick={() => updateQuantity(-1)}
                disabled={quantity <= 1}
                className="w-10 h-10 flex items-center justify-center rounded-l-lg
                         disabled:opacity-50 hover:bg-gray-200 transition-colors"
              >
                <Minus className="w-4 h-4" />
              </button>
              <span className="w-12 text-center font-medium">{quantity}</span>
              <button 
                onClick={() => updateQuantity(1)}
                disabled={quantity >= 99}
                className="w-10 h-10 flex items-center justify-center rounded-r-lg
                         disabled:opacity-50 hover:bg-gray-200 transition-colors"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
            <div className="text-lg font-bold">
              ${totalPrice}
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="w-full bg-black text-white py-3 rounded-lg font-semibold
                     flex items-center justify-center gap-2
                     hover:bg-gray-800 transition-colors"
          >
            加入購物車
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DishOptionModal;