import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { 
  ShoppingCart, 
  ChevronRight, 
  ChevronLeft,
  Clock, 
  AlertCircle, 
  Loader2,
  X, 
  Menu,
  Plus,
  Minus,
  CheckCircle 
} from 'lucide-react';
import moment from 'moment';
import { message } from 'antd';
import liff from '@line/liff';
import CheckoutModal from './CheckoutModal';
import CartModal from './Components/CartModal';
import { calculateItemPrice } from './Components/OrderTotalCalculator';
import DishCard from './Components/DishCard';
import DishOptionModal from './Components/DishOptionModal';
import OrderTypeModal from './Components/OrderTypeModal';


const PCLOUD_IMAGE_PATH = process.env.REACT_APP_PCLOUD_IMAGE_PATH;

// GraphQL Queries
const GET_RESTAURANT_DATA = gql`
  query GetRestaurantData($restaurantId: Int!) {
    restaurants_by_pk(id: $restaurantId) {
      id
      name
      announcement
      announcement_enabled
      online_order_enabled
      delivery_enabled
      takeout_enabled
      dine_in_enabled
    }
    categories(
      where: { restaurant_id: { _eq: $restaurantId } },
      order_by: { display_order: asc }
    ) {
      id
      name
      description
      is_time_restricted
      start_time
      end_time
      dishes(
        where: { 
          availability_status: { 
            _in: ["available", "unavailable_today"] 
          }
        }, 
        order_by: { display_order: asc }
      ) {
        id
        name
        description
        price
        image_url
        availability_status
        display_order
        comb_dish_options(order_by: { display_order: asc }) {
          dish_option {
            id
            name
            type
            min_selections
            max_selections
            display_order
          }
        }
      }
    }
    dish_options(order_by: { display_order: asc }) {
      id
      name
      type
      min_selections
      max_selections
      display_order
      comb_option_items(order_by: { display_order: asc }) {
        dish_option_item {
          id
          name
          price
          display_order
        }
      }
    }
    dish_option_items(order_by: { display_order: asc }) {
      id
      name
      price
      display_order
    }
    tables(
      where: { 
        restaurant_id: { _eq: $restaurantId },
        status: { _eq: "available" }
      }
    ) {
      id
      table_number
      status
    }
  }
`;

const CREATE_ORDER = gql`
  mutation CreateOrder($object: orders_insert_input!) {
    insert_orders_one(object: $object) {
      id
      table_id
      total_amount
      created_at
      status
      pay_method
      order_type
      customer_info
      expected_time
    }
  }
`;

const CREATE_ORDER_ITEMS = gql`
  mutation CreateOrderItems($objects: [order_items_insert_input!]!) {
    insert_order_items(objects: $objects) {
      affected_rows
      returning {
        id
        order_id
        dish_id
        quantity
        unit_price
        subtotal
        dish_items_text
      }
    }
  }
`;




const OrderApp = ({ restaurantId }) => {
  // States
  const [currentTime, setCurrentTime] = useState(moment());
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cart, setCart] = useState([]);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedDish, setSelectedDish] = useState(null);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [showOrderTypeModal, setShowOrderTypeModal] = useState(true);
  const [stickyCategories, setStickyCategories] = useState(false);
  const [userClickedCategory, setUserClickedCategory] = useState(false);

  // Refs
  const scrollTimeout = useRef(null);
  const categoryNavRef = useRef(null);
  const sectionRefs = useRef({});
  const mainContentRef = useRef(null);

  // Queries and Mutations
  const { loading, error, data } = useQuery(GET_RESTAURANT_DATA, {
    variables: { restaurantId }
  });
  const [createOrder] = useMutation(CREATE_ORDER);
  const [createOrderItems] = useMutation(CREATE_ORDER_ITEMS);

  // 新增總價計算函數
  const totalPrice = useMemo(() => {
    return cart.reduce((sum, item) => {
      // 基本價格
      let basePrice = Number(item.price) || 0;
      
      // 選項加價計算
      let optionsTotal = 0;
      if (item.options) {
        Object.entries(item.options).forEach(([optionId, selectedItems]) => {
          if (Array.isArray(selectedItems)) {
            // 多選選項的價格計算
            selectedItems.forEach(itemId => {
              const optionItem = data?.dish_option_items?.find(oi => oi.id === parseInt(itemId));
              if (optionItem) {
                optionsTotal += Number(optionItem.price) || 0;
              }
            });
          } else {
            // 單選選項的價格計算
            const optionItem = data?.dish_option_items?.find(oi => oi.id === parseInt(selectedItems));
            if (optionItem) {
              optionsTotal += Number(optionItem.price) || 0;
            }
          }
        });
      }

      // 計算單項商品總價 = (基本價格 + 選項加價) × 數量
      const itemTotal = (basePrice + optionsTotal) * item.quantity;
      return sum + itemTotal;
    }, 0);
  }, [cart, data?.dish_option_items]);


  // Cart Functions
  const handleUpdateQuantity = (index, newQuantity) => {
    const newCart = [...cart];
    newQuantity = Math.max(1, Math.min(99, newQuantity)); // 限制數量在 1-99 之間
    newCart[index] = { ...newCart[index], quantity: newQuantity };
    setCart(newCart);
  };

  const handleRemoveFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    message.success('已從購物車移除');
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      const itemTotal = calculateItemPrice(item, data?.dish_option_items || []) * item.quantity;
      return total + itemTotal;
    }, 0);
  };

  const handleAddToCart = (dish, options = {}, note = '', quantity = 1) => {
    const cartItem = {
      ...dish,
      options,
      note,
      quantity
    };
    setCart([...cart, cartItem]);
    message.success('已加入購物車');
  };

  // Category Functions
  const isCategoryAvailable = (category) => {
    if (!category.is_time_restricted) return true;
    
    const now = moment();
    const currentTime = now.format('HH:mm:ss');
    const startTime = category.start_time;
    const endTime = category.end_time;
    
    if (endTime < startTime) {
      return currentTime >= startTime || currentTime < endTime;
    }
    
    return currentTime >= startTime && currentTime < endTime;
  };

  const scrollToCategory = (categoryId) => {
    setUserClickedCategory(true);
    setSelectedCategory(categoryId);
    
    const element = sectionRefs.current[categoryId];
    if (element && mainContentRef.current) {
      const headerHeight = 140;
      const targetPosition = element.offsetTop - headerHeight;
      mainContentRef.current.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
      
      setTimeout(() => {
        setUserClickedCategory(false);
      }, 800);
    }
    
    const categoryNavContainer = categoryNavRef.current;
    const highlightedButton = categoryNavContainer?.querySelector(
      `[data-category-id="${categoryId}"]`
    );
    
    if (categoryNavContainer && highlightedButton) {
      const containerRect = categoryNavContainer.getBoundingClientRect();
      const buttonRect = highlightedButton.getBoundingClientRect();
      
      if (buttonRect.left < containerRect.left || buttonRect.right > containerRect.right) {
        const scrollLeft = highlightedButton.offsetLeft - 
          (containerRect.width / 2) + (buttonRect.width / 2);
        categoryNavContainer.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        });
      }
    }
  };

  // Checkout Function
  const handleCheckout = async (paymentMethod, orderDetails) => {
    if (cart.length === 0) {
      message.error('購物車是空的');
      return;
    }

    try {
      setIsProcessing(true);

      const orderInput = {
        table_id: orderType === 'dineIn' ? selectedTable : null,
        total_amount: totalPrice,
        status: 'submitted',
        pay_method: paymentMethod,
        order_type: orderType,
        expected_time: orderDetails.deliveryTime,
        priority: orderType === 'delivery' ? orderDetails.priority : 3,
        customer_info: JSON.stringify({
          name: orderDetails.name,
          phone: orderDetails.phone,
          address: orderType === 'delivery' ? orderDetails.address : null,
          priority: orderType === 'delivery' ? orderDetails.priority : 3
        }),
        restaurant_id: restaurantId
      };

      const { data: orderData } = await createOrder({
        variables: { object: orderInput }
      });

      if (!orderData?.insert_orders_one?.id) {
        throw new Error('創建訂單失敗');
      }

      const orderId = orderData.insert_orders_one.id;

      await createOrderItems({
        variables: {
          objects: cart.map(item => ({
            order_id: orderId,
            dish_id: item.id,
            quantity: item.quantity,
            unit_price: item.price,
            subtotal: item.price * item.quantity,
            dish_items_text: JSON.stringify({
              options: item.options,
              note: item.note
            })
          }))
        }
      });

      message.success(`訂單已成功提交！訂單編號：${orderId}`);
      
      // 清空購物車
      setCart([]);
      
      // 關閉所有相關 Modal
      setShowCheckoutModal(false);
      setShowCartModal(false);  // 確保購物車 Modal 也關閉
      setShowOptionModal(false); // 如果有商品選項 Modal 也關閉
      
      // 如果需要，可以重置其他相關狀態
      setSelectedDish(null);

    } catch (error) {
      console.error('提交訂單時發生錯誤：', error);
      message.error('提交訂單失敗：' + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  // Effects
  useEffect(() => {
    if (data?.categories) {
      setCategories(data.categories);
      const availableCategory = data.categories.find(category => {
        if (!category.is_time_restricted) return true;
        
        const now = moment();
        const start = moment(category.start_time, 'HH:mm:ss');
        const end = moment(category.end_time, 'HH:mm:ss');
        
        if (end.isBefore(start)) {
          end.add(1, 'day');
          if (now.isBefore(start)) {
            now.add(1, 'day');
          }
        }
        
        return now.isBetween(start, end, undefined, '[)');
      });
  
      if (availableCategory) {
        setSelectedCategory(availableCategory.id);
        setTimeout(() => {
          scrollToCategory(availableCategory.id);
          setTimeout(() => {
            setUserClickedCategory(false);
          }, 1000);
        }, 100);
      } else {
        setSelectedCategory(data.categories[0]?.id);
      }
    }
  }, [data]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    liff.init({ liffId: process.env.REACT_APP_LIFF_ID }).catch(console.error);
  }, []);

  useEffect(() => {
    if (mainContentRef.current) {
      const handleScroll = () => {
        const scrollPosition = mainContentRef.current.scrollTop;
        setStickyCategories(scrollPosition > 180);
  
        if (!userClickedCategory) {
          const currentCategory = categories.find(category => {
            const element = sectionRefs.current[category.id];
            if (element) {
              const rect = element.getBoundingClientRect();
              return rect.top <= 120 && rect.bottom > 120;
            }
            return false;
          });
  
          if (currentCategory && currentCategory.id !== selectedCategory) {
            setSelectedCategory(currentCategory.id);
            
            const categoryNavContainer = categoryNavRef.current;
            const highlightedButton = categoryNavContainer?.querySelector(
              `[data-category-id="${currentCategory.id}"]`
            );
  
            if (categoryNavContainer && highlightedButton) {
              const containerRect = categoryNavContainer.getBoundingClientRect();
              const buttonRect = highlightedButton.getBoundingClientRect();
  
              if (buttonRect.left < containerRect.left || buttonRect.right > containerRect.right) {
                const scrollLeft = highlightedButton.offsetLeft - 
                  (containerRect.width / 2) + (buttonRect.width / 2);
                categoryNavContainer.scrollTo({
                  left: scrollLeft,
                  behavior: 'smooth'
                });
              }
            }
          }
        }
      };
  
      mainContentRef.current.addEventListener('scroll', handleScroll);
      return () => {
        mainContentRef.current.removeEventListener('scroll', handleScroll);
        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current);
        }
      };
    }
  }, [categories, selectedCategory, userClickedCategory]);



  // Render Methods
const renderRestaurantInfo = () => (
  <div className="bg-white px-4 py-2 border-b">
    <div className="max-w-4xl mx-auto">
      <div className="flex items-center gap-3">
        <img 
          src="/logo192.png"
          alt="Restaurant Logo"
          className="w-10 h-10 object-contain rounded-lg"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/api/placeholder/48/48";
          }}
        />
        <div className="flex-1">
          <h1 className="text-xl font-bold mb-2">{data?.restaurants_by_pk.name}</h1>
          {selectedTable && (
            <div className="flex items-center">
              <span className="inline-block px-3 py-1 bg-gray-100 rounded-full text-sm">
                桌號: {data?.tables.find(t => t.id === selectedTable)?.table_number}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

const renderCategoryNav = () => (
  <div className={`sticky top-0 bg-white z-30 transition-shadow duration-200 ${
    stickyCategories ? 'shadow-md' : ''
  }`}>
    <div className="max-w-4xl mx-auto px-4 py-2">  

    <div 
          ref={categoryNavRef}
          className="flex overflow-x-auto no-scrollbar gap-2 scroll-smooth"
        >
          {categories.map(category => (
            <button
              key={category.id}
              data-category-id={category.id}
              onClick={() => scrollToCategory(category.id)}
              className={`px-4 py-1.5 rounded-full whitespace-nowrap transition-all duration-200
                ${selectedCategory === category.id
                  ? 'bg-black text-white'
                  : 'text-black hover:bg-gray-100'
                }
                ${category.is_time_restricted && !isCategoryAvailable(category)
                  ? 'text-gray-300'
                  : ''
                }               
              `}                
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const renderCategorySection = (category) => {
    const isAvailable = isCategoryAvailable(category);
    const categoryDishes = category.dishes || [];
    const timeRange = category.is_time_restricted ? 
      {
        start: moment(category.start_time, 'HH:mm:ss').format('HH:mm'),
        end: moment(category.end_time, 'HH:mm:ss').format('HH:mm')
      } : null;

    return (
      <section
        key={category.id}
        ref={el => sectionRefs.current[category.id] = el}
        className="mb-4"
      >
        <div className="sticky top-[0px] bg-gray-50 z-20 border-b border-gray-100">
          <div className="max-w-4xl mx-auto px-4 py-2 flex justify-between items-center bg-gray-200">
            <h2 className="text-lg font-bold">{category.name}</h2>
            {category.is_time_restricted && (
              <div className="flex items-center gap-2 text-sm">
                <Clock className="w-4 h-4" />
                <span>
                  {timeRange.start}-{timeRange.end}
                  {!isAvailable && (
                    <span className="ml-2 text-yellow-600">(未營業)</span>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="relative">
          {!isAvailable && (
            <div className="absolute inset-0 bg-black/30 backdrop-blur-[2px] z-10">
              <div className="flex items-center justify-center h-full">
                <div className="bg-white/95 p-4 rounded-lg shadow-lg flex items-center gap-3">
                  <AlertCircle className="text-yellow-500 w-5 h-5" />
                  <span className="font-medium">此時段暫未供應</span>
                </div>
              </div>
            </div>
          )}
    
          {categoryDishes.map(dish => (
            <DishCard
              key={dish.id}
              dish={dish}
              isAvailable={isAvailable}
              onAddToCart={() => {
                setSelectedDish(dish);
                setShowOptionModal(true);
              }}
              unavailableReason={!isAvailable ? '此時段暫未供應' : 
                dish.availability_status === 'unavailable_today' ? '今日已售完' : ''}
            />
          ))}
        </div>
      </section>
    );
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-gray-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-red-500">載入失敗：{error.message}</div>
      </div>
    );
  }

  // Main Return
  return (
    <div className="flex flex-col h-screen bg-white">
      {renderRestaurantInfo()}
      {renderCategoryNav()}

      <main ref={mainContentRef} className="flex-1 overflow-y-auto overscroll-contain">
        <div className="max-w-4xl mx-auto pb-24">
          {categories.map(renderCategorySection)}
        </div>
      </main>

      {/* Modals */}
      <OrderTypeModal 
        isOpen={showOrderTypeModal}
        onClose={() => setShowOrderTypeModal(false)}
        onSelectType={setOrderType}
        announcement={data?.restaurants_by_pk?.announcement}
        isAnnouncementEnabled={data?.restaurants_by_pk?.announcement_enabled}
      />

      <DishOptionModal
        dish={selectedDish}
        isOpen={showOptionModal}
        onClose={() => setShowOptionModal(false)}
        onAddToCart={handleAddToCart}
        dishOptions={data?.dish_options || []}
        dishOptionItems={data?.dish_option_items || []}
      />

      <CartModal
        isOpen={showCartModal}
        onClose={() => setShowCartModal(false)}
        cart={cart}
        onRemoveItem={handleRemoveFromCart}  // 修改為正確的函數名稱
        onUpdateQuantity={handleUpdateQuantity}
        dishOptions={data?.dish_options || []}  // 使用 GraphQL 查詢的資料
        dishOptionItems={data?.dish_option_items || []}  // 使用 GraphQL 查詢的資料
        onCheckout={() => setShowCheckoutModal(true)}
      />


      <CheckoutModal
        isOpen={showCheckoutModal}
        onClose={() => setShowCheckoutModal(false)}
        cart={cart}
        restaurantId={restaurantId}
        totalPrice={calculateTotalPrice()}
        tableNumber={data?.tables.find(t => t.id === selectedTable)?.table_number}
        dishOptions={data?.dish_options || []}
        dishOptionItems={data?.dish_option_items || []}
        categories={categories} 
        onCheckout={handleCheckout}
        orderType={orderType}
        onOrderTypeChange={setOrderType}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />

      {/* 底部購物車按鈕 */}
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 z-40">
        <div className="max-w-4xl mx-auto">
          <button
            onClick={() => setShowCartModal(true)}
            disabled={cart.length === 0}
            className={`w-full py-3 rounded-lg font-semibold flex items-center justify-center gap-3
              ${cart.length === 0 
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-black text-white hover:bg-gray-800'
              } transition-colors duration-200`}
          >
            <ShoppingCart className="w-5 h-5" />
            <span>購物車</span>
            {cart.length > 0 && (
              <>
                <span className="mx-2">|</span>
                <span>${totalPrice}</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderApp;      