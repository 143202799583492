import React from 'react';
import { AlertCircle } from 'lucide-react';

const DishCard = ({ 
  dish, 
  isAvailable, 
  onAddToCart, 
  unavailableReason 
}) => {
  const isDishAvailable = isAvailable && dish.availability_status === 'available';
  
  return (
    <div className={`flex border-b border-gray-100 p-4 hover:bg-gray-50 transition-colors
      ${!isDishAvailable ? 'opacity-75' : ''}`}
    >
      <div className="flex-1 pr-4">
        <h3 className={`font-semibold text-lg mb-1 
          ${!isDishAvailable ? 'text-gray-400' : 'text-gray-900'}`}>
          {dish.name}
        </h3>
        
        {dish.description && (
          <p className={`text-sm mb-2 line-clamp-2
            ${!isDishAvailable ? 'text-gray-400' : 'text-gray-600'}`}>
            {dish.description}
          </p>
        )}
        
        <div className="flex items-center gap-2">
          <span className={`font-medium 
            ${!isDishAvailable ? 'text-gray-400' : 'text-gray-900'}`}>
            ${dish.price}
          </span>
          
          {dish.comb_dish_options?.length > 0 && (
            <span className="text-xs text-gray-500">(可客製化)</span>
          )}
          
          {!isDishAvailable && (
            <span className="text-yellow-600 text-sm flex items-center gap-1">
              <AlertCircle className="w-4 h-4" />
              {unavailableReason}
            </span>
          )}
        </div>
      </div>

      <div className="relative w-24 flex-shrink-0">
        <img
          src={dish.image_url ? `${process.env.REACT_APP_PCLOUD_IMAGE_PATH}${dish.image_url}` : "/api/placeholder/96/96"}
          alt={dish.name}
          className={`w-24 h-24 object-cover rounded-lg ${!isDishAvailable ? 'grayscale' : ''}`}
          loading="lazy"
        />
        
        <button
          onClick={() => isDishAvailable && onAddToCart(dish)}
          disabled={!isDishAvailable}
          className={`absolute -bottom-2 -right-2 w-8 h-8 rounded-full 
            flex items-center justify-center shadow-lg transition-colors duration-200
            ${isDishAvailable 
              ? 'bg-black text-white hover:bg-gray-800' 
              : 'bg-gray-400 cursor-not-allowed'}`}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default DishCard;